import { CheckOutlined, MinusCircleOutlined, PlusCircleOutlined, RotateLeftOutlined } from "@ant-design/icons"
import { Button, Divider, Dropdown, Layout, Menu, Pagination, Select, Space, message } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/Page/AnnotationLayer.css"
import "react-pdf/dist/Page/TextLayer.css"
import { useParams } from "react-router-dom"
import { trackAction, useLesson } from "../../../../hooks/lesson-context"
import { useElementSize } from "../../../../hooks/useElementSize"
import { useEnrolmentActivtyMutation } from "../../../../network/useEnrolmentActivity"

export const Pdf = ({ lessonQuery, moduleQuery, isPreviewing, materialAsJSON }) => {
  const { enrolmentUid, lessonUid, enrolmentType } = useParams()
  const { state, dispatch } = useLesson()

  const mutator = useEnrolmentActivtyMutation()
  const { t } = useTranslation()

  const { ref, width, height } = useElementSize()
  const pdfContainerSizes = useElementSize()

  const [numPages, setNumPages] = useState()
  const [currPage, setCurrPage] = useState(1)
  const [scale, setScale] = useState(null)
  const [initialScale, setInitialScale] = useState(null)
  const [rotation, setRotation] = useState(0)

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  useEffect(() => {
    dispatch({ type: "field", field: "actionRunning", payload: false })
  }, [])

  const calculateInitialScale = (page) => {
    if (!pdfContainerSizes.width || !pdfContainerSizes.height || !page) return 1

    const containerWidth = pdfContainerSizes.width
    const containerHeight = pdfContainerSizes.height

    const pageWidth = page.originalWidth
    const pageHeight = page.originalHeight

    const scaleX = containerWidth / pageWidth
    const scaleY = containerHeight / pageHeight

    return Math.min(scaleX, scaleY)
  }

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const handlePageLoadSuccess = (page) => {
    if (!initialScale) {
      const calculatedScale = calculateInitialScale(page)
      setInitialScale(calculatedScale)
      setScale(calculatedScale)
    }
  }

  const zoomIn = () => {
    setScale((prev) => prev * 1.1)
  }

  const zoomOut = () => {
    setScale((prev) => prev * 0.9)
  }

  return (
    <Layout style={{ background: "#f8f8f881" }}>
      <div ref={pdfContainerSizes.ref} className="pdf__container">
        {pdfContainerSizes.width && pdfContainerSizes.height && (
          <>
            <div className="header">
              <Space>
                {pdfContainerSizes.width >= 1020 && (
                  <>
                    <Pagination
                      pageSize={1}
                      defaultCurrent={currPage}
                      current={currPage}
                      total={numPages}
                      onChange={(page) => {
                        setCurrPage(page)
                      }}
                      showSizeChanger={false}
                      size="small"
                      showQuickJumper
                    />
                    <Divider type="vertical" />
                  </>
                )}
                {pdfContainerSizes.width >= 1020 && (
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => setScale(0.5)}>50%</Menu.Item>
                        <Menu.Item onClick={() => setScale(1)}>100%</Menu.Item>
                        <Menu.Item onClick={() => setScale(1.5)}>150%</Menu.Item>
                        <Menu.Item onClick={() => setScale(2)}>200%</Menu.Item>
                        <Menu.Item onClick={() => setScale(2.5)}>250%</Menu.Item>
                      </Menu>
                    }
                  >
                    <Button size="small">Zoom: {Math.floor(scale * 100)}%</Button>
                  </Dropdown>
                )}
                <Button onClick={zoomOut} type="primary" size="small" icon={<MinusCircleOutlined />} />
                <Button onClick={zoomIn} type="primary" size="small" icon={<PlusCircleOutlined />} />
                {pdfContainerSizes.width >= 1020 && (
                  <>
                    <Divider type="vertical" />
                    <Button icon={<RotateLeftOutlined />} size="small" onClick={() => setRotation(rotation + 90)}>
                      Rotate
                    </Button>
                  </>
                )}
              </Space>
              <Space>
                <Button
                  onClick={() => {
                    if (currPage > 1) {
                      setCurrPage(currPage - 1)
                    }
                  }}
                  type="secondary"
                  size="small"
                >
                  {t("Previous")}
                </Button>
                <Button
                  loading={state?.actionRunning}
                  disabled={state?.actionRunning}
                  icon={<CheckOutlined />}
                  onClick={async () => {
                    if (currPage < numPages) {
                      setCurrPage(currPage + 1)
                    } else {
                      if (!isPreviewing) {
                        await trackAction({
                          publicationEnrolmentUid: enrolmentUid,
                          dispatch,
                          mutator,
                          payload: {
                            typeUid: lessonUid,
                            type: "lesson",
                            status: "completed",
                          },
                        })
                      }
                      if (!state?.actionRunning) {
                        dispatch({
                          type: "next",
                          payload: {
                            isPreviewing,
                            lessonUid,
                            enrolmentUid,
                            enrolmentType,
                            currentModule: moduleQuery?.data,
                            ...lessonQuery?.data?.next,
                          },
                        })
                      } else {
                        message?.info("Saving progress...")
                      }
                    }
                  }}
                  type="primary"
                  size="small"
                >
                  {currPage >= numPages ? t("Complete") : t("nextPage")}
                </Button>
              </Space>
            </div>

            <Document
              loading={""}
              onLoadError={() => {
                message.error("Error loading PDF")
              }}
              onLoadSuccess={handleDocumentLoadSuccess}
              file={materialAsJSON.url}
              className="pdf__renderer"
            >
              <Page
                rotate={rotation}
                className="pdf__page"
                pageNumber={currPage}
                scale={scale}
                onLoadSuccess={handlePageLoadSuccess}
              />
            </Document>
          </>
        )}
      </div>
    </Layout>
  )
}
