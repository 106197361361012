import { Avatar, Button, Menu, Space, Typography } from "antd"
import React from "react"
import { Link } from "react-router-dom"
import { GlobalOutlined, RollbackOutlined, UploadOutlined, UserOutlined } from "@ant-design/icons"
import { useAuthSignOut } from "@react-query-firebase/auth"
import { auth } from "../firebase"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"

export function NavDropdown({ switchToAdmin = false }) {
  const { mutate: signOutMutation } = useAuthSignOut(auth)
  const { t, i18n } = useTranslation()

  const queryClient = useQueryClient()

  const signOut = async () => {
    window.localStorage.clear()
    window.sessionStorage.clear()
    window.localStorage.setItem("returnUrl", window.location.pathname)
    window.localStorage.setItem("tenantId", queryClient.getQueryData("token").claims.firebase.tenant)
    window.localStorage.setItem("i18nextLng", i18n.language)
    await signOutMutation()
  }

  const items = [
    {
      label: (
        <Link to="/profile">
          <Space size={16} style={{ padding: "0.225rem" }}>
            <Avatar
              className="menu__dropDown"
              icon={<UserOutlined />}
              style={{ height: "1.75rem", width: "1.75rem" }}
            />
            <Typography.Text style={{ color: "#808291" }}>{t("Profile")}</Typography.Text>
          </Space>
        </Link>
      ),
      key: "profile",
      style: {
        marginBottom: ".125rem",
      },
    },
    ...(switchToAdmin
      ? [
          {
            label: (
              <a href={`https://admin.${window.location.host}${queryClient.getQueryData("token").claims.firebase.tenant ? `?tenantId=${queryClient.getQueryData("token").claims.firebase.tenant}` : ""}`}>
                <Space size={16} style={{ padding: "0.225rem" }}>
                  <Avatar
                    className="menu__dropDown"
                    icon={<GlobalOutlined />}
                    style={{ height: "1.75rem", width: "1.75rem" }}
                  />
                  <Typography.Text style={{ color: "#808291" }}>Switch to admin</Typography.Text>
                </Space>
              </a>
            ),
            key: "switch-to-admin",
          },
        ]
      : []),
    {
      label: (
        <Space size={16} style={{ padding: "0.225rem" }}>
          <Avatar
            className="menu__dropDown"
            icon={<RollbackOutlined />}
            style={{ height: "1.75rem", width: "1.75rem" }}
          />
          <Typography.Text style={{ color: "#808291" }}>{t("Signout")}</Typography.Text>
        </Space>
      ),
      key: "signout",
      onClick: () => signOut(),
      style: {
        marginBottom: ".125rem",
      },
    },
  ]

  return <Menu items={items} />
}
