import {
  ClockCircleOutlined,
  CloudDownloadOutlined,
  DollarOutlined,
  EditOutlined,
  ExportOutlined,
  InfoCircleFilled,
  SendOutlined,
  StarFilled,
  StopOutlined,
} from "@ant-design/icons"
import {
  Avatar,
  Button,
  Card,
  Col,
  Comment,
  Divider,
  Empty,
  Form,
  Image,
  Input,
  Modal,
  Rate,
  Result,
  Row,
  Skeleton,
  Space,
  Tabs,
  Tooltip,
  Typography,
  message,
} from "antd"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import duration from "dayjs/plugin/duration"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { Link, useLocation, useParams } from "react-router-dom"
import { CertificatePreviews } from "../../../components/CertificatePreviews"
import { CertificateDrawerWithFetch } from "../../../components/CertificateDrawerWithFetch"
import { Container } from "../../../components/Container"
import { EnrolmentActionButton } from "../../../components/EnrolmentActionButton"
import { Hero } from "../../../components/Hero"
import { TagFactory } from "../../../components/TagFactory"
import { SmallCard } from "../../../components/cards/SmallCard"
import { isModuleLocked } from "../../../helpers/isModuleLocked"
import { progressFactory } from "../../../helpers/progressFactory"
import { tabFactory } from "../../../helpers/tabsFactory"
import { makeProfileImg } from "../../../hooks/makeProfileImg"
import { useGetQueryParam } from "../../../hooks/useGetQueryParam"
import useWindowDimensions from "../../../hooks/useWindowDimensions"
import { useClipboard } from "../../../network/use-clipboard"
import { useEnrolmentMutation, useGetEnrolment } from "../../../network/useEnrolment"
import { useGetMaterial } from "../../../network/useMaterial"
import { useGetMedia } from "../../../network/useMedia"
import { fetchModule } from "../../../network/useModule"
import { useGetPerson } from "../../../network/usePerson"
import { CourseModuleModal } from "./CourseModuleModal"

dayjs.extend(relativeTime)
dayjs.extend(duration)

const { Title } = Typography

export function Courses() {
  const { courseUid } = useParams()
  const { get } = useGetQueryParam("moduleUid")
  const courseQuery = useGetEnrolment({ uid: courseUid })
  const materialQuery = useGetMaterial({ enrolmentUid: courseUid })
  const { t, i18n } = useTranslation()

  const personQuery = useGetPerson({ authenticated: true })
  const queryClient = useQueryClient()

  const windowDimensions = useWindowDimensions()
  const enrolmentMutation = useEnrolmentMutation(courseUid)
  const clipboard = useClipboard({ timeout: 500 })

  const [form] = Form.useForm()
  const [visible, setVisible] = useState(true)
  const [showCertificate, setShowCertificate] = useState(false)
  const [noticeVisible, setNoticeVisible] = useState(false)
  const [mediaTypeUid, setMediaTypeUid] = useState(null)
  const [mediaType, setMediaType] = useState(null)
  const mediaQuery = useGetMedia({
    type: mediaType,
    typeUid: mediaTypeUid,
  })
  const { pathname } = useLocation()

  useEffect(() => {
    window?.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (!courseQuery.isLoading && courseQuery.data) {
      setMediaType(courseQuery.data?.publication?.type + "s")
      setMediaTypeUid(courseQuery.data?.publication?.typeUid)
    }
  }, [courseQuery.isLoading, courseQuery.data])

  const courseProgress = useMemo(() => {
    if (!courseQuery.isLoading && !courseQuery.isFetching && courseQuery.data) {
      if (courseQuery.data?.publication?.noticeUrl) {
        setNoticeVisible(true)
      }
      return progressFactory.course(courseQuery.data).resultAsPercent
    }
  }, [courseQuery.isLoading, courseQuery.data, courseQuery.isFetching])

  const expired = useMemo(() => {
    if (!courseQuery.isLoading && !courseQuery.isFetching && courseQuery.data) {
      if (courseQuery?.data?.currentState !== "completed") return ""
      if (!courseQuery?.data?.completionValidUntil) return ""
      const difference = dayjs(courseQuery?.data?.completionValidUntil).diff(dayjs(), "days")
      if (difference > 0) {
        if (difference > 365) {
          return (
            <TagFactory icon={<ClockCircleOutlined />} status="Expiring">
              Expires in over a year
            </TagFactory>
          )
        } else {
          return (
            <TagFactory icon={<ClockCircleOutlined />} status="Expiring">
              Expires {dayjs().to(courseQuery?.data?.completionValidUntil)}
            </TagFactory>
          )
        }
      } else if (difference < 0) {
        return (
          <TagFactory icon={<StopOutlined />} status="Expired">
            Expired {dayjs(courseQuery?.data?.completionValidUntil).from(dayjs())}
          </TagFactory>
        )
      }
    }
    return ""
  }, [courseQuery.isLoading, courseQuery?.isFetching, courseQuery.data])

  return (
    <>
      {!courseQuery.isLoading && !courseQuery.isFetching ? (
        <>
          {courseQuery.data && !courseQuery.isError ? (
            <>
              <Link className="floatingBack floatingBack--fixed floatingBack--dark" to="/">
                Back
              </Link>
              {get()?.[0] && (
                <CourseModuleModal courseUid={courseUid} courseQuery={courseQuery} moduleUid={get()?.[0]} />
              )}
              {!personQuery.isLoading &&
                personQuery.data &&
                personQuery?.data.company?.tenantId === "JTI-Brand-Accelerato-alhb2" && (
                  <Modal
                    footer={null}
                    visible={visible}
                    onCancel={() => setVisible(false)}
                    className="modal modal--noPadding"
                  >
                    <img style={{ width: "100%", objectFit: "contain" }} src="/jit_banner.jpg" alt="" />
                  </Modal>
                )}
              <Modal
                footer={null}
                visible={noticeVisible}
                onCancel={() => setNoticeVisible(false)}
                className="modal modal--noPadding"
              >
                <img
                  style={{ width: "100%", objectFit: "contain" }}
                  src={courseQuery?.data?.publication?.noticeUrl}
                  alt=""
                />
              </Modal>
              <Hero autoplay>
                <Hero.Slide img={courseQuery.data.publication?.featureImageUrl}>
                  <Comment
                    avatar={
                      courseQuery.data.publication.publishedBy?.imageUrl ||
                      makeProfileImg(`${courseQuery.data.publication.publishedBy?.name[0]}`)
                    }
                    author={<p style={{ color: "white", marginBottom: 0 }}>{t("Publisher")}</p>}
                    content={courseQuery.data.publication.publishedBy?.name}
                  />
                  <Space size={20} align="center">
                    <Hero.Title style={{ margin: 0 }}>{courseQuery.data.publication.title}</Hero.Title>
                    <Space>
                      {courseQuery.data?.publication?.noticeUrl ? (
                        <InfoCircleFilled onClick={() => setNoticeVisible(true)} size={24} />
                      ) : null}
                      {courseQuery.data?.publication?.hasReward ? (
                        <Tooltip title="This enrolment will earn you a reward">
                          <DollarOutlined size={24} />
                        </Tooltip>
                      ) : null}
                    </Space>
                  </Space>
                  <Hero.Description>{courseQuery.data.publication.translations?.["1"]?.summary}</Hero.Description>
                  <Space>
                    <div
                      style={{
                        display: "flex",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      {courseQuery?.isLoading || courseQuery?.isFetching ? (
                        <Button type="primary" loading>
                          Determining Progress
                        </Button>
                      ) : (
                        <EnrolmentActionButton enrolment={courseQuery.data} />
                      )}
                      {courseQuery.data.publication?.reviewScore ? (
                        <div>
                          <StarFilled style={{ color: "#FFD700" }} />
                          <span>
                            {" "}
                            {courseQuery.data.publication?.reviewScore} {t("Rating")}
                          </span>
                        </div>
                      ) : null}
                    </div>

                    {expired}
                  </Space>
                  {courseProgress && courseProgress > 0 && courseProgress < 100 ? (
                    <div className="progress">
                      <Tooltip title={`Course Progress: ${courseProgress}%`}>
                        <div className="percentage" style={{ width: `${courseProgress}%` }} />
                      </Tooltip>
                    </div>
                  ) : null}
                </Hero.Slide>
              </Hero>
              <Container style={{ marginTop: "2.5rem", paddingBottom: "7rem" }}>
                <Tabs
                  style={{ marginTop: "1rem", padding: "0 1rem" }}
                  defaultActiveKey={tabFactory().get() || "Modules"}
                  onChange={(keyValue) => tabFactory().onChange(keyValue)}
                >
                  <Tabs.TabPane tab={t("About")} key="About">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: courseQuery.data.publication.translations?.["1"]?.descriptionAsHTML,
                      }}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={t("Resources")} key="Resources">
                    {!mediaQuery.isLoading ? (
                      <Row gutter={[32, 32]}>
                        {mediaQuery.data && mediaQuery.data?.files?.length > 0 && !mediaQuery.isError ? (
                          <>
                            {mediaQuery.data?.files?.map((file) => (
                              <Col key={file.uid} xs={24} lg={8}>
                                <Card
                                  cover={
                                    file?.media?.type === "image" ? (
                                      <Image
                                        alt="example"
                                        style={{
                                          aspectRatio: "16/9",
                                          objectFit: "cover",
                                        }}
                                        src={file?.media?.url}
                                      />
                                    ) : (
                                      <Image
                                        alt="example"
                                        style={{
                                          aspectRatio: "16/9",
                                          objectFit: "cover",
                                        }}
                                        preview={false}
                                        src={`/${file?.media?.type}.png`}
                                      />
                                    )
                                  }
                                  actions={[
                                    <Tooltip title="Download" key="download">
                                      <a href={file?.media?.url} download target="_blank">
                                        <CloudDownloadOutlined />
                                      </a>
                                    </Tooltip>,
                                    <Tooltip key="Share" title="Share">
                                      <ExportOutlined
                                        onClick={() => {
                                          clipboard.copy(file?.media?.url)
                                          message.success("Copied link")
                                        }}
                                      />
                                    </Tooltip>,
                                  ]}
                                >
                                  <Card.Meta
                                    title={file.media.title}
                                    description={
                                      <Space direction="vertical">
                                        <TagFactory style={{ textTransform: "capitalize" }} status={file?.media?.type}>
                                          {file?.media?.type}
                                        </TagFactory>
                                      </Space>
                                    }
                                  />
                                </Card>
                              </Col>
                            ))}
                          </>
                        ) : (
                          // <div style={{ background: "red" }}>
                          <Result
                            style={{ width: "100%" }}
                            status="404"
                            title="No Resources Yet"
                            subTitle="Any resources uploaded will appear here."
                          />
                          // </div>
                        )}
                      </Row>
                    ) : (
                      <Skeleton />
                    )}
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={t("Modules")} key="Modules">
                    {!materialQuery?.isLoading && !courseQuery?.isFetching ? (
                      <>
                        {materialQuery?.data?.length ? (
                          <Row gutter="32">
                            <Col xs={24} md={18}>
                              <Row gutter="32">
                                {materialQuery?.data.map((material, i) => {
                                  const tags = []
                                  if (
                                    courseQuery?.data?.activity?.find(
                                      (x) => x?.typeUid === material?.uid && x?.status === "completed"
                                    )
                                  ) {
                                    tags?.push(t("Completed"))
                                  } else {
                                    tags?.push({
                                      value: `${t("Lessons")}: ${material?.lessonCount}`,
                                      color: "blue",
                                    })
                                  }
                                  const materialFromEnrolment = courseQuery?.data?.publication?.material?.find(
                                    (x) => x?.uid === material?.uid
                                  )

                                  // if (i === 3) {
                                  //   console.log("materialFromEnrolment:::", {
                                  //     mat: materialFromEnrolment.duration,
                                  //     dur: dayjs.duration(materialFromEnrolment.duration).humanize(),
                                  //   })
                                  // }

                                  const duration = dayjs.duration(materialFromEnrolment.duration).humanize()
                                  console.log("duration:::", duration)

                                  return (
                                    <Col xs={24} md={12} key={material?.uid}>
                                      <SmallCard
                                        onMouseEnter={() =>
                                          queryClient.prefetchQuery(
                                            [courseQuery?.data?.uid, "module", material?.uid],
                                            () => fetchModule(courseQuery?.data?.uid, material?.uid, queryClient),
                                            {
                                              staleTime: 1000 * 60 * 2,
                                            }
                                          )
                                        }
                                        // progress={
                                        //   progressFactory.module(
                                        //     material,
                                        //     courseQuery.data?.activity || []
                                        //   ).resultAsPercent
                                        // }
                                        href={`/courses/${courseUid}?moduleUid=${material.uid}`}
                                        url={material?.featureImageUrl}
                                        topics={tags}
                                        meta1={
                                          material.dueDate
                                            ? dayjs(material.dueDate).format("DD/MM/YYYY")
                                            : t("noDueDate")
                                        }
                                        meta2={duration.includes("NaN") || !duration ? "" : duration}
                                        title={material?.title}
                                        locked={
                                          courseQuery?.data?.currentState === "completed"
                                            ? false
                                            : isModuleLocked(
                                                material,
                                                courseQuery?.data?.publication,
                                                courseQuery?.data?.activity,
                                                null,
                                                courseQuery?.data?.isLocked
                                              )
                                        }
                                      />
                                    </Col>
                                  )
                                })}
                              </Row>
                            </Col>
                            <Col xs={24} md={6}>
                              <Title level={5}>{courseQuery.data.feedback ? t("Review") : t("leaveAReview")}</Title>
                              {!courseQuery.data.feedback && (
                                <>
                                  <p className="badge--meta">{t("rateOutOf5")}</p>
                                  <Form
                                    name="review"
                                    form={form}
                                    onFinish={(values) => {
                                      enrolmentMutation.mutate(
                                        {
                                          enrolmentUid: courseUid,
                                          data: {
                                            feedback: {
                                              ...values,
                                            },
                                          },
                                        },
                                        {
                                          onSuccess: () => {
                                            form.resetFields()
                                            queryClient.invalidateQueries(courseUid)
                                          },
                                        }
                                      )
                                    }}
                                  >
                                    <Form.Item style={{ margin: 0 }} name="rating" rules={[{ required: true }]}>
                                      <Rate />
                                    </Form.Item>
                                    <div className="no-marg-top filter">
                                      <Form.Item
                                        className="filter__input filter__input--noMargin"
                                        name="comment"
                                        rules={[{ required: true }]}
                                      >
                                        <Input
                                          bordered={false}
                                          placeholder={t("leaveAComment") + "..."}
                                          // placeholder="Leave a comment..."
                                          prefix={<EditOutlined />}
                                        />
                                      </Form.Item>
                                      <Button
                                        loading={enrolmentMutation.isLoading}
                                        htmlType="submit"
                                        type="primary"
                                        icon={<SendOutlined />}
                                        size="small"
                                      />
                                    </div>
                                  </Form>
                                  <Divider />
                                </>
                              )}
                              {courseQuery?.data?.feedback && (
                                <Comment
                                  content={<p>{courseQuery?.data?.feedback?.comment}</p>}
                                  datetime={`${courseQuery?.data?.feedback?.rating}/5`}
                                />
                              )}
                            </Col>
                          </Row>
                        ) : (
                          <Empty
                            image="/empty.png"
                            imageStyle={{
                              height: 60,
                            }}
                            description={<span>This course does not have any modules.</span>}
                          >
                            <Button href="/" type="primary">
                              Back to {">"} My Learning
                            </Button>
                          </Empty>
                        )}
                      </>
                    ) : (
                      <Skeleton />
                    )}
                  </Tabs.TabPane>

                  {courseQuery?.data?.currentState === "completed" ? (
                    <Tabs.TabPane tab="Certificate" key={"Certificate"}>
                      {courseQuery.data.publication.certificationRequiresStudentSignature &&
                      !courseQuery.data.completionAcknowledgedAt ? (
                        <>
                          <h2>This course earned you a certificate</h2>
                          <p>To download and sign your certificate, please click the button below.</p>
                          <Button onClick={() => setShowCertificate(true)}>View Certificate</Button>
                        </>
                      ) : (
                        <CertificatePreviews enrolment={courseQuery.data} />
                      )}
                      <CertificateDrawerWithFetch
                        enrolmentUid={courseUid}
                        visible={showCertificate}
                        onClose={() => setShowCertificate(false)}
                        enrolment={courseQuery?.data}
                      />
                    </Tabs.TabPane>
                  ) : null}
                </Tabs>
              </Container>
            </>
          ) : (
            <Empty
              image="/empty.png"
              imageStyle={{
                height: 60,
              }}
              description={<span>You are not enrolled to any courses</span>}
            >
              <Button href="/" type="primary">
                Back to My Learning
              </Button>
            </Empty>
          )}
        </>
      ) : (
        <>
          <Skeleton.Input style={{ width: windowDimensions.width, height: "40vh" }} active />
          <Container style={{ marginTop: "2.5rem" }}>
            <Row gutter="32">
              {[1, 1, 1].map((_, i) => (
                <Col xs={24} md={12} lg={8} key={i}>
                  <Card size="small" bordered={false} className="smallCard">
                    <Skeleton active />
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </>
      )}
    </>
  )
}
